<template>
  <v-app>
    <v-navigation-drawer v-model="controls.drawer" width="322px" floating app>
      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height: 322px"
      >
        <div class="app-logo-sheet">
          <v-img
            :src="require('@/assets/images/app-logo.png')"
            width="150px"
            height="150px"
          />
        </div>
        <div class="app-company--name mt-8">That Pet App</div>
        <div class="app-project--name mt-2">Admin CMS</div>
      </v-layout>

      <v-list>
        <template v-for="(item, itemIndex) in navItems">
          <v-divider
            v-if="item.children && item.active"
            :key="`${itemIndex}-first-divider`"
          />

          <v-list-group
            :class="{ 'grey lighten-5': item.active }"
            v-if="item.children"
            :key="itemIndex"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 nav-item" v-text="item.label" />
              </v-list-item-content>
            </template>

            <v-list-item
              class="app-main-nav"
              v-for="(subItem, subItemmIndex) in item.children"
              :key="subItemmIndex"
              :to="subItem.to"
              v-ripple="{ class: 'primary--text lighten-4' }"
              @click="open(item.id)"
              ripple
              link
            >
              <v-list-item-content>
                <v-list-item-title class="pa-2 pl-5 nav-item">
                  {{ subItem.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            class="app-main-nav"
            :key="itemIndex"
            :to="item.to"
            v-ripple="{ class: 'primary--text lighten-4' }"
            @click="open(item.id)"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="pa-2 nav-item">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="item.children && item.active"
            :key="`${itemIndex}-second-divider`"
          />
        </template>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="user.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email ? user.email : user.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'profile' }">
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main class="main-content">
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
        <slot />
      </div>
    </v-main>
    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import UserAvatar from '@/components/UserAvatar'

import { mapState, mapActions, mapGetters } from 'vuex'
import { each } from 'lodash'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
    ConfirmModal,
  },

  mixins: [ControlsMixin],

  data() {
    return {
      navItems: [
        { id: 1, label: 'Users', active: false, to: { name: 'users' } },
        {
          id: 2,
          label: 'Options',
          active: false,
          children: [
            {
              id: 1,
              label: 'Species & Breeds',
              active: false,
              to: { name: 'species-and-breeds' },
            },
            {
              id: 2,
              label: 'Medication Types',
              active: false,
              to: { name: 'medication-types' },
            },
            {
              id: 3,
              label: 'Appointment Types',
              active: false,
              to: { name: 'appointment-types' },
            },
            {
              id: 4,
              label: 'Contact Types',
              active: false,
              to: { name: 'contact-types' },
            },
            {
              id: 5,
              label: 'Medical Record Types',
              active: false,
              to: { name: 'medical-record-types' },
            },
            {
              id: 5,
              label: 'Other Reminder Types',
              active: false,
              to: { name: 'other-reminder-types' },
            },
          ],
        },
        { id: 5, label: 'Settings', active: false, to: { name: 'settings' } },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),

    open(itemId) {
      each(this.navItems, (item) => {
        item.active = item.id === itemId

        return item
      })
    },
  },
}
</script>
